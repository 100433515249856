import React from 'react';
import '../styles/table.css';

const calgary = {
  Calgary: [189],
  Airdrie: [195.84],
  Chestermere: [150],
  Cochrane: [150],
  Okotoks: [175],
  'Foothills County': [250],
  'Rocky View County': [150],
};

const redDeer = {
  'Red Deer': [125],
  'Sylvan Lake': [130.15],
  Blackfalds: [75],
  Penhold: [77.65],
  'Red Deer County': [100],
  Lacombe: [85],
  'Lacombe County': [100],
};

const alberta = {
  Beiseker: [50],
  Bentley: [30],
  Bighorn: [155.15],
  Cardston: [25],
  Carstairs: [78.75],
  Champion: [40],
  'Clearwater County': [80],
  Cremona: [100],
  Crossfield: [108],
  Delburne: [77.65],
  'Diamond Valley': [350],
  Didsbury: [100],
  Eckville: [50],
  'Fort Saskatchewan': [280],
  'High River': [150],
  Irricana: [75],
  'Kneehill County': [100],
  Leduc: [110],
  'Leduc County': [173],
  'Mountain View County': [150],
  Nanton: [55],
  'Newell ': [100],
  'Town of Nobleford': [30],
  'Pincher Creek': [50],
  Ponoka: [50],
  'Rocky Mountain House': [75],
  Stavely: [50],
  Strathmore: [170],
  Sundre: [125],
  Trochu: [50],
  'Taber, MD': [42],
  'Village of Rockyford': [120],
  'Village of Stirling': [65],
  Vulcan: [75],
  'Vulcan County': [75],
  'Wheatland County': [100],
  'Willow Creek MD': [103.74],
};

const ComplianceCosts = () => (
  <>
    <p>Cost of Compliance for Calgary and surrounding municipalities as of January 2025</p>
    <table className="table-price">
      <thead>
        <tr>
          <th>Municipality</th>
          <th>Rate</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(calgary).map(([city, [rate, rush]]) => (
          <tr key={city}>
            <td>{city}</td>
            <td className="text-right">${parseFloat(rate).toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </table>
    <br />

    <p>Cost of Compliance for Red Deer and the surrounding municipalities as of January 2025</p>
    <table className="table-price">
      <thead>
        <tr>
          <th>Municipality</th>
          <th>Rate</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(redDeer).map(([city, [rate, rush]]) => (
          <tr key={city}>
            <td>{city}</td>
            <td className="text-right">${parseFloat(rate).toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </table>
    <br />

    <p>Cost of Compliance for other municipalities in Alberta as of January 2025</p>
    <table className="table-price">
      <thead>
        <tr>
          <th>Municipality</th>
          <th>Rate</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(alberta).map(([city, [rate, rush]]) => (
          <tr key={city}>
            <td>{city}</td>
            <td className="text-right">${parseFloat(rate).toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </>
);

export default ComplianceCosts;
